export class ChainedSelect {
    constructor(child) {
        this.parents = [];
        this.child = child;
        this.parents = this.findParents();
        if (this.parents.length == 0) {
            return;
        }
        this.fullData = child.find(".data-chained").detach();
        this.emptyValue = child.find("option[data-filter-emptyvalue]").detach();
        if (this.emptyValue.length == 0) {
            this.emptyValue = child.find("[value='']");
        }
        this.onChanged();
    }
    findParents() {
        const parents = [];
        const parentsId = [];
        const data = this.child.find("option").each((index, element) => {
            const data = $(element).data();
            for (const key in data) {
                if (!data.hasOwnProperty(key)) {
                    continue;
                }
                if (key.indexOf("chained_") !== -1) {
                    const parentId = key
                        .substring(8)
                        .replace(/([A-Z])/, "-$1")
                        .toLowerCase();
                    if (parentsId.indexOf(parentId) !== -1) {
                        continue;
                    }
                    parentsId.push(parentId);
                    const parent = $(`#${parentId}`);
                    if (parent.length > 0) {
                        parent.on("change", () => this.onChanged());
                        parents.push(parent);
                    }
                }
            }
        });
        return parents;
    }
    onChanged() {
        if (this.parents.length === 0) {
            return;
        }
        this.child.find("option.data-chained").remove();
        this.child.find("option[data-filter-emptyvalue]").remove();
        let options = [];
        this.parents.forEach((parent) => {
            const id = parent.attr("id");
            const value = parent.val();
            if (value && id !== "") {
                if (options.length === 0) {
                    options = this.fullData;
                }
                options = options.filter(function (index) {
                    const optionParentsId = $(this).data(`chained_${id}`);
                    if (Array.isArray(optionParentsId)) {
                        return (optionParentsId !== undefined &&
                            optionParentsId.indexOf(value) > -1);
                    }
                    return optionParentsId == value;
                });
                // "option[data-chained_" + id + "='" + value + "']"
            }
        });
        this.child.append(options);
        if (options.length == 0) {
            this.child.append(this.emptyValue);
        }
        this.selectFirst(options);
    }
    selectFirst(options) {
        let option = this.getAlreadySelectedOption();
        if (option.length == 0) {
            option = this.getFirstOption();
        }
        if (option.length != 0) {
            this.child.val(option.val()).trigger("change");
        }
    }
    getAlreadySelectedOption() {
        return this.child.find("option[selected]");
    }
    getFirstOption() {
        return this.child.find("option").eq(0);
    }
    static initialize() {
        $("select.chained").each((index, element) => new ChainedSelect($(element)));
    }
}
