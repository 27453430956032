export class CenterDetailComments {
    constructor() {
        this.reviewDomObjectTemplate = $("#review-template");
        this.url = this.reviewDomObjectTemplate.data("url");
        this.moreCommentsButton = $("#more-comments");
        this.loadingMessage = $("#loading");
        this._loading = false;
        this.errorMessage = $("#error");
        this.moreCommentsButton.on("click", (event) => {
            event.preventDefault();
            if (this.loading) {
                return;
            }
            this.loadNextPage();
        });
        this.loadNextPage();
    }
    set loading(loading) {
        if (loading) {
            this.loadingMessage.removeClass("d-none");
            this.moreCommentsButton.addClass("d-none");
        }
        else {
            this.loadingMessage.addClass("d-none");
            this.moreCommentsButton.removeClass("d-none");
        }
        this._loading = loading;
    }
    get loading() {
        return this._loading;
    }
    loadNextPage() {
        this.loading = true;
        this.errorMessage.addClass("d-none");
        $.ajax(this.url, {
            headers: {
                Accept: "application/json",
            },
            success: (response) => {
                if (response.error) {
                    this.onErrorResponse();
                }
                else {
                    this.onSuccessResponse(response);
                }
            },
            error: (data) => {
                this.loading = false;
            },
        });
    }
    onSuccessResponse(response) {
        if (response.data && response.data.length > 0) {
            this.reviewsBlockVisible = true;
            for (const reviewData of response.data) {
                this.addReview(reviewData);
            }
        }
        this.loading = false;
        this.url = response.nextUrl;
        if (this.url === "") {
            this.commentsButtonVisible = false;
        }
    }
    set commentsButtonVisible(visible) {
        this.setObjectVisibility(this.moreCommentsButton, visible);
    }
    set reviewsBlockVisible(visible) {
        this.setObjectVisibility($("#reviews"), visible);
    }
    setObjectVisibility(object, visible) {
        if (visible) {
            object.removeClass("d-none");
        }
        else {
            object.addClass("d-none");
        }
    }
    onErrorResponse() {
        this.loading = false;
        this.commentsButtonVisible = false;
        this.reviewsBlockVisible = true;
        this.errorMessage.removeClass("d-none");
    }
    addReview(reviewData) {
        const reviewDomObject = this.reviewDomObjectTemplate.clone();
        reviewDomObject.removeClass("d-none");
        reviewDomObject.find(".certificate").html(this.getTitle(reviewData));
        reviewDomObject.find(".comment-date").html(`Publicado: ${reviewData.date}`);
        reviewDomObject
            .find(".comment")
            .html(this.getFormattedComment(reviewData.comment));
        this.addScores(reviewDomObject, reviewData.scores);
        reviewDomObject.insertBefore(this.reviewDomObjectTemplate);
    }
    addScores(reviewDomObject, scores) {
        const scoreTemplate = reviewDomObject.find("#score-review-template");
        for (const score of scores) {
            const scoreDomObject = scoreTemplate.clone();
            scoreDomObject.removeClass("d-none");
            scoreDomObject.find(".name").html(score.name);
            const scoreBar = scoreDomObject.find(".progress-bar");
            scoreBar
                .addClass(`score-${score.score}`)
                .attr("aria-valuenow", score.score)
                .html(score.score);
            scoreDomObject.insertAfter(scoreTemplate);
        }
    }
    getFormattedComment(comment) {
        return `<p>${comment.replace(/([\t ]*\n[\t ]*){2,}/g, "</p><p>")}</p>`;
    }
    getTitle(review) {
        return `${this.getCertificateFullName(review)}`;
    }
    getCertificateFullName(review) {
        const { session } = review;
        return `${session.language} ${session.certificate} ${session.exam}`;
    }
}
