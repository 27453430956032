export class Buy {
    constructor() {
        this.birthDateInput = $("#birth_date");
        this.legalGuardianInputs = $(".legal-guardian");
        if ($("form#buy").length === 0) {
            return;
        }
        this.initializeCustomerFields();
        this.checkInitialCollapseStatus();
        this.initializeExtraChildrenStatus();
    }
    initializeCustomerFields() {
        this.birthDateInput.on("change", () => {
            this.checkAge();
        });
        this.checkAge();
    }
    checkAge() {
        if (this.age < 15) {
            this.legalGuardianInputs.removeClass("d-none");
            this.legalGuardianInputs.find("input").prop("required", true);
        }
        else {
            this.legalGuardianInputs.addClass("d-none");
            this.legalGuardianInputs.find("input").prop("required", false);
        }
    }
    get age() {
        const yearNow = new Date().getFullYear();
        const monthNow = new Date().getMonth() + 1;
        const dayNow = new Date().getDate();
        const birthDate = new Date(this.birthDateInput.val());
        const month = birthDate.getMonth() + 1;
        const day = birthDate.getDate();
        const year = birthDate.getFullYear();
        if ((monthNow === month && dayNow < day) || monthNow < month) {
            return yearNow - year - 1;
        }
        else {
            return yearNow - year;
        }
    }
    checkInitialCollapseStatus() {
        $("input[type=checkbox][data-toggle]").each(function () {
            const target = $(this).data("target");
            if ($(this).prop("checked")) {
                $(target).addClass("show");
            }
            else {
                $(target).removeClass("show");
            }
        });
    }
    initializeExtraChildrenStatus() {
        const parentsAnalyzed = [];
        $('[data-parent-id!=""][data-parent-id]').each((index, element) => {
            const parentId = $(element).data("parentId");
            if (parentsAnalyzed.indexOf(parentId) != -1) {
                return;
            }
            const parentValue = $(element).data("valueParent");
            const children = $(`[data-parent-id=${parentId}]`);
            children.addClass("collapse");
            const parent = $(`#extra${parentId}`);
            parent.on("change", () => Buy.onParentChanged(parent, children));
            parentsAnalyzed.push(parentId);
            parent.trigger("change");
        });
    }
    static onParentChanged(parent, children) {
        let value;
        if (parent.is(":checkbox")) {
            value = parent.is(":checked") ? "1" : "0";
        }
        else {
            value = parent.val();
        }
        const childrenToShow = children.filter(`[data-value-parent='${value}']`);
        childrenToShow.collapse("show");
        childrenToShow
            .filter(".required")
            .find(":input:not(:checkbox)")
            .prop("required", true);
        const childrenToHide = children.filter(`[data-value-parent!='${value}'][data-value-parent]`);
        childrenToHide.collapse("hide");
        childrenToHide
            .filter(".required")
            .find(":input:not(:checkbox)")
            .prop("required", false);
    }
}
