export class ReloadWithReplace {
    constructor(element) {
        this.element = element;
        if (!element.is("select")) {
            return;
        }
        this.paramName = element.attr("name");
        if (this.paramName === undefined) {
            throw new Error("Falta el parámetro name en elemento .reload-with-replace");
        }
        this.element.on("change", () => this.onChanged());
    }
    get value() {
        return this.element.val();
    }
    onChanged() {
        const params = new URLSearchParams(window.location.search);
        params.set(this.paramName, this.value);
        window.location.search = params.toString();
    }
    static initialize() {
        $(".reload-with-replace").each((index, element) => {
            new ReloadWithReplace($(element));
        });
    }
}
