export class Sidebar {
    constructor(element) {
        const sidebarId = element.dataset.target;
        if (!sidebarId === undefined) {
            return;
        }
        const sidebar = document.querySelector(`#${sidebarId}`);
        element.addEventListener("click", () => {
            sidebar.classList.toggle("open");
            document.querySelector("body").classList.toggle("overflow-hidden");
        });
    }
    static initialize() {
        document
            .querySelectorAll(this.buttonSelector)
            .forEach((element) => {
            new Sidebar(element);
        });
    }
}
Sidebar.buttonSelector = ".js-sidebar-button";
