import { ScrollTo } from "./scroll-to";
export class FilterCollapsed {
    constructor() {
        $("[data-target='#filter']").on("click", function (event) {
            if ($("#filter").length === 1) {
                event.preventDefault();
            }
        });
        $("#filter").on("shown.bs.collapse", function (event) {
            ScrollTo.doScroll($("#filter"));
        });
    }
}
