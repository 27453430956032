export class AddCenterForm {
    constructor() {
        this.error = $("#error");
        this.province = $("#id_province");
        this.city = $("#city");
        $("#center-form").on("submit", (event) => this.onFormSubmitted(event));
        document
            .querySelectorAll(".activity-type-toggle")
            .forEach((element) => {
            element.addEventListener("click", (event) => {
                document.querySelector(".form-body").classList.add("d-none");
                const target = event.target;
                const activityTypeId = target.dataset.id;
                this.changeConditions(activityTypeId);
                window.setTimeout(() => {
                    document.querySelector(".form-body").classList.remove("d-none");
                    $(".select2").trigger("select2:re-initialize");
                }, 200);
            });
        });
    }
    changeConditions(activityTypeId) {
        document.querySelectorAll(".js-conditions").forEach((element) => {
            element.classList.add("d-none");
        });
        document
            .querySelector(`.js-conditions.js-${activityTypeId}`)
            .classList.remove("d-none");
    }
    showError(message) {
        this.error.html(message).removeClass("d-none");
    }
    onFormSubmitted(event) {
        this.error.html("").addClass("d-none");
        if (!this.checkPasswords() ||
            !this.checkLocation() ||
            !this.checkConditions()) {
            event.preventDefault();
        }
    }
    checkLocation() {
        const province = this.province.val();
        const city = this.city.val();
        if (!province || !city) {
            this.showError(cityProvinceRequired);
            return false;
        }
        return true;
    }
    checkPasswords() {
        const password1 = $("#password").val();
        const password2 = $("#password2").val();
        if (password1.length < AddCenterForm.MIN_PASSWORD_CHARACTERS) {
            this.showError(passwordMin.replace("{passwordMin}", String(AddCenterForm.MIN_PASSWORD_CHARACTERS)));
            return false;
        }
        if (password2 !== password1) {
            this.showError(passwordMismatch);
            return false;
        }
        return true;
    }
    static init() {
        new AddCenterForm();
    }
    checkConditions() {
        const checked = $("#conditions").prop("checked");
        if (!checked) {
            this.showError(conditionsNotAccepted);
        }
        return checked;
    }
}
AddCenterForm.MIN_PASSWORD_CHARACTERS = 6;
