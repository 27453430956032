export class RangeCopyValue {
    constructor(rangeInput) {
        this.rangeInput = rangeInput;
        const targetSelector = rangeInput.data("copyTo");
        this.target = $(targetSelector);
        if (this.target.length == 0) {
            throw new Error(`No se encuentra ${targetSelector}`);
        }
        rangeInput.on("change", () => this.onChange());
    }
    onChange() {
        if (this.target.is(":input")) {
            this.target.val(this.rangeInput.val());
        }
        else {
            this.target.html(this.rangeInput.val());
        }
    }
    static initialize() {
        $("input[type=range][data-copy-to]").each((index, element) => new RangeCopyValue($(element)));
    }
}
