export class MaxCount {
    constructor(element) {
        this.element = element;
        this.maxCount = parseInt($(element).data("maxCount"));
        this.target = this.findTarget();
        $(element).on("change keyup", () => this.refreshCounter());
        this.refreshCounter();
    }
    findTarget() {
        const targetName = $(this.element).data("maxCountTarget");
        if (targetName === undefined) {
            throw new Error("data-max-count-target not found");
        }
        const target = $(targetName);
        if (target.length === 0) {
            throw new Error(`${targetName} no encontrado`);
        }
        return target;
    }
    get currentLength() {
        return this.element.val().length;
    }
    refreshCounter() {
        if (this.currentLength > this.maxCount) {
            this.element.val(this.element.val().substr(0, this.maxCount));
        }
        this.target.html(`${this.currentLength}/${this.maxCount}`);
    }
    static initialize() {
        $("[data-max-count]:input").each((index, element) => {
            new MaxCount($(element));
        });
    }
}
