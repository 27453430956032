import { Select } from "./select2/select";
import { AutoReload } from "./auto-reload";
import { ChainedSelect } from "./chained-select";
import { FilterCollapsed } from "./filter-collapsed";
import { MaxCount } from "./max-count";
import { RangeCopyValue } from "./range-copy-value";
import { ReloadWithReplace } from "./reload-with-replace";
import { onDocumentReady } from "./on-document-ready";
import { ScrollTo } from "./scroll-to";
import { TinySlider } from "./tiny-slider";
import { Sidebar } from "./sidebar";
onDocumentReady(function () {
    initializeSelect2();
    initializeAutoReload();
    initializeChainedSelects();
    initializeFilterCollapsed();
    initializeMaxCountInput();
    initializeRangeCopy();
    initializeReloadWithReplace();
    initializeScrollTo();
    TinySlider.initialize();
    Sidebar.initialize();
});
function initializeSelect2() {
    Select.initialize();
}
function initializeAutoReload() {
    AutoReload.initialize();
}
function initializeChainedSelects() {
    ChainedSelect.initialize();
}
function initializeFilterCollapsed() {
    new FilterCollapsed();
}
function initializeMaxCountInput() {
    MaxCount.initialize();
}
function initializeRangeCopy() {
    RangeCopyValue.initialize();
}
function initializeReloadWithReplace() {
    ReloadWithReplace.initialize();
}
function initializeScrollTo() {
    ScrollTo.initialize();
}
