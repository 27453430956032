export class ScrollTo {
    static doScroll(targetId) {
        $(targetId).removeClass("blink");
        $([document.documentElement, document.body]).animate({
            scrollTop: $(targetId).offset().top,
        }, {
            duration: 1000,
            complete: () => $(targetId).addClass("blink"),
        });
    }
    static fromButton(button) {
        button.on("click", () => ScrollTo.doScroll(button.data("scrollTo")));
    }
    static initialize() {
        $("[data-scroll-to]").each((index, element) => {
            ScrollTo.fromButton($(element));
        });
    }
}
