export class SearchProvinceSelector {
    static initialize() {
        const btn = document.body.querySelector("button#btn-towns");
        if (btn === null) {
            return;
        }
        btn.addEventListener("click", function () {
            const towns = document.querySelector('[name="jump"] [name="menu"]');
            window.open(towns.options[towns.selectedIndex].value);
        });
    }
}
