import { Buy } from "./buy";
import { CenterDetailComments } from "./center-detail-comments";
import { FilterForm } from "./filter-form";
import { AddCenterForm } from "./insert-center";
import { onDocumentReady } from "../common/on-document-ready";
import { GroupDetail } from "./group-detail/group-detail";
import { SearchProvinceSelector } from "./search-province-selector";
import { Tooltip } from "../common/tooltip";
import { Purchase } from "./payment/purchase";
onDocumentReady(function () {
    initializeCheckoutForm();
    initializeCenterComments();
    initializeSessionSearchForm();
    initializeRegisterCenterForm();
    initializeTooltip();
    GroupDetail.initialize();
    SearchProvinceSelector.initialize();
    Purchase.initialize();
});
function initializeCheckoutForm() {
    new Buy();
}
function initializeCenterComments() {
    new CenterDetailComments();
}
function initializeSessionSearchForm() {
    new FilterForm();
}
function initializeRegisterCenterForm() {
    AddCenterForm.init();
}
function initializeTooltip() {
    new Tooltip();
}
