export class GroupDetail {
    constructor() {
        this.provinceSelector();
        this.examSelector();
    }
    provinceSelector() {
        const btnProvincess = document.body.querySelector("button#btn-provinces");
        if (btnProvincess === null) {
            return;
        }
        btnProvincess.addEventListener("click", function () {
            const provinces = document.querySelector('[name="jump1"] [name="provinces"]');
            window.open(provinces.options[provinces.selectedIndex].value);
        });
    }
    examSelector() {
        const btnExams = document.body.querySelector("button#btn-exams");
        if (btnExams === null) {
            return;
        }
        btnExams.addEventListener("click", function () {
            const exams = document.querySelector('[name="jump2"] [name="exams"]');
            window.open(exams.options[exams.selectedIndex].value);
        });
    }
    static initialize() {
        new GroupDetail();
    }
}
