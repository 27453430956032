export class Purchase {
    constructor(form) {
        this.form = form;
        this.sending = false;
        const paymentMethodElement = document.getElementById(Purchase.PAYMENT_METHODS_ELEMENT_ID);
        form.addEventListener("submit", async (e) => {
            e.preventDefault();
        });
        if (paymentMethodElement instanceof HTMLSelectElement) {
            paymentMethodElement.addEventListener("change", async () => {
                this.preparePayment(paymentMethodElement.selectedOptions[0]);
            });
        }
        else {
            this.preparePayment(paymentMethodElement);
        }
    }
    async sendForm(form) {
        if (this.sending) {
            return null;
        }
        this.hideError();
        this.disableForm();
        const apiUrl = form.action;
        const { method } = form;
        const formData = new FormData(form);
        const fetchOptions = {
            method,
            body: formData,
        };
        try {
            const response = await fetch(apiUrl, fetchOptions);
            const json = await response.json();
            if (!response.ok) {
                this.setError(json);
                this.enableForm();
                return null;
            }
            return json;
        }
        catch (e) {
            this.enableForm();
            return Promise.reject(e);
        }
    }
    setError(json) {
        const { errorElement } = this;
        errorElement.classList.remove("d-none");
        errorElement.textContent = json.message;
    }
    hideError() {
        const { errorElement } = this;
        errorElement.classList.add("d-none");
        errorElement.textContent = "";
    }
    get errorElement() {
        return this.form.querySelector(Purchase.ERROR_ELEMENT_SELECTOR);
    }
    async preparePayment(element) {
        const { providerId, procedureId } = element.dataset;
        const prepareProcedure = await this.getPrepareProcedure(providerId, procedureId);
        prepareProcedure(this.form, this.sendForm.bind(this));
    }
    async getPrepareProcedure(providerId, procedureId) {
        const paymentMethod = await import(`./payment-methods/${providerId}/${procedureId}`);
        return paymentMethod.preparePayment;
    }
    static initialize() {
        const form = document.querySelector("form#buy");
        if (form === null) {
            return;
        }
        new Purchase(form);
    }
    disableForm() {
        this.sending = true;
    }
    enableForm() {
        this.sending = false;
    }
}
Purchase.BUTTON_WRAPPER_SELECTOR = ".js-button-wrapper";
Purchase.BUTTON_SELECTOR = ".js-button";
Purchase.ERROR_ELEMENT_SELECTOR = ".js-error";
Purchase.PAYMENT_METHODS_ELEMENT_ID = "payment-methods";
